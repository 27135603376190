<template>
  <div class="assets-type-form-dev">
    <a :class="wrapClass" class="assets-type-form" href="javascript:;" @click="modalAssetsType = true">{{valueLabel}}</a>
    <fm-modal v-model="modalAssetsType" width="500px" theme="mh-blackt">
      <div style="width: 100%;height:70vh;">
        <assets-type-tree :is-select="true" @change="change"></assets-type-tree>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import AssetsTypeTree from './assetsTypeTree'
import Vue from 'vue'

export default {
  name: 'AssetsTypeForm',
  components: {
    AssetsTypeTree
  },
  data () {
    return {
      modalAssetsType: false,
      onError: false
    }
  },
  props: {
    value: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    valueLabel () {
      let text = null
      if (this.value && this.$store.getters.assetsTypeList) {
        let assetsType = this.getAssetsType(this.value, this.$store.getters.assetsTypeList)
        if (assetsType) {
          text = assetsType.code + ' ' + assetsType.name
        }
      }
      return text
    },
    wrapClass () {
      return {
        'verifier-error': this.onError
      }
    }
  },
  methods: {
    getAssetsType (id, assetsTypeList) {
      let i = 0
      let find = null
      while (!find && i < assetsTypeList.length) {
        if (assetsTypeList[i].id === id) {
          find = assetsTypeList[i]
        } else if (assetsTypeList[i].child && assetsTypeList[i].child.length > 0) {
          find = this.getAssetsType(id, assetsTypeList[i].child)
        }
        i += 1
      }
      return find
    },
    verifier () {
      const msg = this.$verifier.check(this.value, false, [])
      console.log('msg verifier', msg, msg === '')
      this.$emit('verifier', msg)
      this.onError = msg !== ''
      return msg
    },
    change (data) {
      this.modalAssetsType = false
      this.$emit('change', data.id)
      Vue.nextTick(() => {
        this.verifier()
      })
    }
  }
}
</script>

<style lang="less" scope>
.assets-type-form {
  width: 100%;
  border-radius: 4px;
  transition: all 0.2s;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0;
  border: 1px solid #dcdee2;
  align-items: center;
  box-shadow: 0 0 0px 3px transparent;
  font-size: 14px;
  color: #515a6e;
  padding: 0 8px;
  height: 30px;
}
.assets-type-form-dev {
  // 报错设置
    .verifier-error {
      border-color: red;
    }
}
</style>